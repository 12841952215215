import React from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";
import mechLogo from '../../../images/locks/product-pages/mech/logo-gray-mech.png';
import cleoLogo from '../../../images/locks/product-pages/cleo/logo-gray-cleo.png';

const IndexPage = () => {
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo
            title={t('products_mechanical_locks')}
            description={t('des_products_mechanical_locks')}
        />
        <div className="products electronic-locks-page">

          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/locks/hero-mech.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Mech"
            />
            <h1>
              <Trans>
                mechanical_lock
              </Trans>
            </h1>
          </section>
          <section className={'mech-locks-overview'}>
            <div className="container">
              <h2 className={'headline'}>
                <Trans>
                  mechanical_headline
                </Trans>
              </h2>
              <p>
                <Trans>
                  mechanical_headline_copy
                </Trans>
              </p>
              <div>
                <ul>
                  <li><Trans>mechanical_headline_list_1</Trans></li>
                  <li><Trans>mechanical_headline_list_2</Trans></li>
                  <li><Trans>mechanical_headline_list_3</Trans></li>
                  <li><Trans>mechanical_headline_list_4</Trans></li>
                  <li><Trans>mechanical_headline_list_5</Trans></li>
                  <li><Trans>mechanical_headline_list_6</Trans></li>
                  <li><Trans>mechanical_headline_list_7</Trans></li>
                </ul>
              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="cleo">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/electronic-locks/cleo-group.png"
                      loading={'lazy'}
                      width={500}
                      height={317}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Cleo locks"
                      className={'cleo-family'}
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <img className={'logo'} src={cleoLogo} width={'142'} height={'54'} alt="Cleo Logo"/>
                  <h2 dangerouslySetInnerHTML={{__html:t('mechanical_cleo_headline')}} />
                  <ul>
                    <li><Trans>mechanical_cleo_list_1</Trans></li>
                    <li><Trans>mechanical_cleo_list_2</Trans></li>
                    <li><Trans>mechanical_cleo_list_3</Trans></li>
                    <li><Trans>mechanical_cleo_list_3_1</Trans></li>
                    <li><Trans>mechanical_cleo_list_4</Trans></li>
                    <li><Trans>mechanical_cleo_list_5</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/mechanical-locks/cleo/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container" />

          <section className="elec-mech">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img className={'logo'} src={mechLogo} width={'142'} height={'54'} alt="Mech Logo"/>
                  <StaticImage
                      src="../../../../images/locks/product-pages/mech/logo-gray-mech.png"
                      loading={'lazy'}
                      width={142}
                      height={54}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Cleo logo"
                  />
                  <h2>
                    <Trans>mechanical_mech_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>mechanical_mech_list1</Trans></li>
                    <li><Trans>mechanical_mech_list2</Trans></li>
                    <li><Trans>mechanical_mech_list3</Trans></li>
                    <li><Trans>mechanical_mech_list5</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/mechanical-locks/mech/'} label={t('see_specs')} cls={'btn btn--orange'} />
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/electronic-locks/mech-with-key.png"
                      loading={'lazy'}
                      width={275}
                      height={244}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Mech"
                      className={'mech-lock'}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
